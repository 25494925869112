import React, { Component, Fragment } from 'react';
import HistoryList from '../History/List';
import UserInfo from '../User/Info';
import ChangeLocation from '../ChangeLocation';

class History extends Component {  
   
    render() {
        
        return (
            <Fragment>
                <UserInfo location={"history"} userSelected={this.props.userSelected} />
                <ChangeLocation location={"account"} text={"Ir a resumen de cuenta"} locationChangedHandler={this.props.locationChangedHandler}></ChangeLocation>
                <HistoryList token={this.props.token} userSelected={this.props.userSelected} orderSelectedHandler={this.props.orderSelectedHandler} />
            </Fragment>
        )
    }
}

export default History;