import React, { Component } from 'react';
import ReactTable from "react-table-v6";  
import "react-table-v6/react-table.css";
import ApiClient from '../../apiClient';
import TableUtils from '../../tableUtils';
import { Container } from 'reactstrap';
import CurrencyCell from '../CurrencyCell';
import { FaClock, FaDownload } from 'react-icons/fa';
const moment = require('moment');

class AccountList extends Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        let apiClient = new ApiClient(this.props.token);
        apiClient.get('account/' + this.props.userSelected.CLIENTE).then(res => {
            this.setState({ list: res.data, loading: false });
        })
        .catch(err => {
            console.error(err);
        });
    }

    render() {
        
        if (this.state.loading) {
            return (
              <p className={"text-center"}>Cargando...</p>
            );
        }

        let tableUtils = new TableUtils();

        const columns = [
            { Header: 'Comprobante', id: 'TIPO_COMPROBANTE', Cell: row => (<div>{row.original.VENCE_PROXIMO_DIA_D && <FaClock className={"icon mr-1 ml-1"} />} { row.original.TIPO_COMPROBANTE }</div>) },
            { Header: 'Número', accessor: 'NRO_COMPROBANTE' },
            { Header: 'F.Emisión', id: 'FECHA_EMISION', width: 100, accessor: d => { return moment.utc(d.FECHA_EMISION).format("DD/MM/YYYY")} },
            { Header: 'F.Vto', id: 'FECHA_VENCIMIENTO', width: 100, accessor: d => { return moment.utc(d.FECHA_VENCIMIENTO).format("DD/MM/YYYY")} },
            { Header: 'Importe Original', className: "text-right", id: 'IMPORTE', width: 130, Cell: row => (<CurrencyCell tableUtils={tableUtils} number={row.original.IMPORTE} />), accessor: 'IMPORTE' },
            { Header: 'Saldo', id: 'SALDO', className: "text-right", width: 110, Cell: row => (<CurrencyCell tableUtils={tableUtils} number={row.original.SALDO} />), accessor: 'SALDO' },
            { Header: 'Pedido #', width: 120, className: "text-right", accessor: 'NUMERO_PEDIDO' },
            { Header: '', width: 50, className: "text-center", id: 'UBICACION_PDF', Cell: row => (row.original.UBICACION_PDF ? <FaDownload className={"icon"} /> : null) }
        ];

        return (
            <Container>
                <ReactTable  
                  {...tableUtils.settings()}
                  data={tableUtils.sortAsc(this.state.list, 'FECHA_VENCIMIENTO')}  
                  columns={columns}  
                  defaultPageSize = {10}  
                  pageSizeOptions = {[10,20,50,100]}
                  sortable={false}
                  className={"ReactTable-clickeable"}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (rowInfo) {
                            if (column.id == 'UBICACION_PDF') {
                                if (rowInfo.original.UBICACION_PDF) {
                                    let tableUtils = new TableUtils();
                                    tableUtils.download(this.props.token, 'account/download/' + this.props.userSelected.CLIENTE + '?file=' + rowInfo.original.UBICACION_PDF);
                                }
                                else {
                                    this.props.orderSelectedHandler(rowInfo.original, 'account');
                                }
                            }
                            else {
                                this.props.orderSelectedHandler(rowInfo.original, 'account');
                            }
                        }
                      }
                    }
                  }}
              />
            </Container>
        )
    }
}

export default AccountList;