import React, { Component } from 'react';

class CurrencyCell extends Component {  
    
    render() {
        let currency = this.props.tableUtils.toCurrency(this.props.number);
        if (this.props.number < 0)
            return <span className={"currency-negative"}>{currency}</span>
        return currency;
    }
}

export default CurrencyCell;