import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

class UserSelect extends Component {  
    
    handleUserSelect = (selected) => {
        this.props.userSelectedHandler(selected.length == 0 ? null : selected[0]);
    }
    
    render() {
        let selected = [];
        if (this.props.userSelected != null)
            selected.push(this.props.userSelected);
        return (
            <Typeahead
                id="userSelect"
                labelKey="NOMBRE_CLIENTE"
                options={this.props.users}
                placeholder="Escriba el nombre del usuario..."
                onChange={this.handleUserSelect}
                selected={selected}
            />
        )
    }
}

export default UserSelect;