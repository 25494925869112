import React, { Component } from 'react';
import './App.css';
import Account from './components/Account/';
import History from './components/History/';
import Order from './components/Order/';
import Unauthorized from './components/Unauthorized';
import ApiClient from './apiClient';
import {userIsBagues} from './userUtils';

const queryString = require('query-string');

class App extends Component {  
  
  constructor(props) {
    super(props);
    this.userSelectedHandler = this.userSelectedHandler.bind(this);
    this.locationChangedHandler = this.locationChangedHandler.bind(this);
    this.orderSelectedHandler = this.orderSelectedHandler.bind(this);
    
    // Obtengo del querystring los tokens
    const query = queryString.parse(this.props.location.search);
    let token = { accessKey: query.AccessKey, login: query.Login };
    
    this.state = {
      location: 'account',
      loading: true,
      unauthorized: false,
      currentUser: null,
      userSelected: null,
      orderSelected: null,
      orderBackTo: null,
      token: token,
      users: []
    };
  }

  userSelectedHandler(user) {
    this.setState({ userSelected: user });
  }

  locationChangedHandler(location) {
    this.setState({ location: location });
  }

  orderSelectedHandler(order, orderBackTo) {
    this.setState({ location: 'order', orderSelected: order, orderBackTo: orderBackTo });
  }

  componentDidMount() {
    let apiClient = new ApiClient(this.state.token);
    apiClient.get('customer/me').then(res => {
        if (res.error) {
            this.setState({ loading: false, unauthorized: true });
        }
        else {
            // Chequeo rol
            this.setState({ currentUser: res.data });
            if (userIsBagues(res.data)) {
              // Es usuario BAGUES (obtengo todos los usuarios)
              apiClient.get('customer').then(res => {
                this.setState({ loading: false, users: res.data });
              }).catch(err => {
                console.error(err);
                this.setState({ loading: false, unauthorized: true });
              });
            }
            else {
              this.setState({ loading: false });
            }
        }
    })
    .catch(err => {
        console.error(err);
        this.setState({ loading: false, unauthorized: true });
    });
  }

  render() {
    
    if (this.state.loading) {
      return (
        <p className={"text-center"}>Cargando...</p>
      )
    }
    if (this.state.unauthorized) {
      return <Unauthorized />
    }

    switch (this.state.location) {
      case 'history':
        return <History
          token={this.state.token}
          userSelected={this.state.userSelected}
          locationChangedHandler={this.locationChangedHandler}
          orderSelectedHandler={this.orderSelectedHandler}
        />
      case 'order':
        return <Order
          token={this.state.token}
          userSelected={this.state.userSelected}
          orderSelected={this.state.orderSelected}
          orderBackTo={this.state.orderBackTo}
          locationChangedHandler={this.locationChangedHandler}
        />
      default:
        return <Account
          token={this.state.token}
          currentUser={this.state.currentUser}
          users={this.state.users}
          userSelected={this.state.userSelected}
          userSelectedHandler={this.userSelectedHandler}
          locationChangedHandler={this.locationChangedHandler}
          orderSelectedHandler={this.orderSelectedHandler}
        />
    }
  }
}

export default App;