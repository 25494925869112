import React, { Component } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { Container, Card, Row, Col } from 'reactstrap';
import TableUtils from '../../tableUtils';

class OrderHeader extends Component {  
    
    discount() {
        let tableUtils = new TableUtils();
        let discount = '';
        if (this.props.list.length > 0) {
            const item = this.props.list[0];
            discount = item.PAPEL + ' (' + tableUtils.toPercent(item.DESCUENTO_PRIMER_NIVEL) + ' + ' + tableUtils.toPercent(item.DESCUENTO_SEGUNDO_NIVEL) + ')';
        }
        return discount;
    }

    total() {
        let tableUtils = new TableUtils();
        let total = 0;
        for (var i = 0; i < this.props.list.length; i++) {
            total += this.props.list[i].IMPORTE;
        }
        return 'Total: ' + tableUtils.toCurrency(total);
    }

    render() {
        return (
            <Container>
                <Row className={"block"}>
                    <Col>
                        <Card body className={"block-pink clearfix"}>
                            <Row>
                                <Col xs="6">
                                    <FaShoppingCart className={"mr-2"} /> Pedido: {this.props.orderSelected.NUMERO_PEDIDO}
                                </Col>
                                <Col xs="6" className="text-md-right">
                                    {this.discount()} {this.total()}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default OrderHeader;