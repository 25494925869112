import React, { Component, Fragment } from 'react';
import AccountList from '../Account/List';
import UserInfo from '../User/Info';
import ChangeLocation from '../ChangeLocation';
import UserSelect from '../UserSelect';
import {userIsBagues} from '../../userUtils';

class Account extends Component {  
    
    constructor(props) {
        super(props);
        this.handleUserSelect = this.handleUserSelect.bind(this);
        this.state = {
            userSelected: props.userSelected
        };
    }

    handleUserSelect(user) {
        this.props.userSelectedHandler(user);
        this.setState({ userSelected: user });
    }

    componentWillMount() {
        // Chequeo rol
        if (!userIsBagues(this.props.currentUser)) {
            // No es usuario BAGUES
            this.handleUserSelect(this.props.currentUser);
        }
    }

    render() {
        // Chequeo rol para mostrar la seleccion de usuario
        return (
            <Fragment>
                {userIsBagues(this.props.currentUser) && <UserSelect users={this.props.users} userSelected={this.state.userSelected} userSelectedHandler={this.handleUserSelect} />}
                {this.state.userSelected && <UserInfo location={"account"} userSelected={this.state.userSelected} />}
                {this.state.userSelected && <ChangeLocation location={"history"} text={"Ir a histórico de cuenta"} textPagoFacil={"Pago Fácil"} locationChangedHandler={this.props.locationChangedHandler} currentUser={this.props.currentUser}></ChangeLocation>}
                {this.state.userSelected && <AccountList token={this.props.token} userSelected={this.state.userSelected} orderSelectedHandler={this.props.orderSelectedHandler} />}
            </Fragment>
        )
    }
}

export default Account;