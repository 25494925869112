import React, {Component} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {FaPrint} from 'react-icons/fa';

class ChangeLocation extends Component {

    constructor(props) {
        super(props);
        this.changeLocation = this.changeLocation.bind(this);
    }

    changeLocation() {
        this.props.locationChangedHandler(this.props.location);
    }

    print(e) {
        e.preventDefault();
        window.print();
    }

    buildBarCode(currentUser) {
        let client = currentUser.CLIENTE.toString().padStart(8, "0");
        let company = "1965"

        let indexToCalculateFirstDigit = [1, 3, 5, 7, 9, 3, 5, 7, 9, 3, 5, 7]
        let indexToCalculateSecondDigit = [1, 3, 5, 7, 9, 3, 5, 7, 9, 3, 5, 7, 9]

        const companyAndClient = company.concat(client)

        let totalFirst = 0;
        for (let i = 0; i < indexToCalculateFirstDigit.length; i++) {
            totalFirst = totalFirst + (parseInt(companyAndClient[i]) * indexToCalculateFirstDigit[i])
        }
        const firstDigit = Math.trunc((totalFirst / 2)) % 10

        let totalSecond = 0;
        const companyAndClientAndFirstDigit = company.concat(client).concat(firstDigit.toString())
        for (let i = 0; i < indexToCalculateSecondDigit.length; i++) {
            totalSecond = totalSecond + (parseInt(companyAndClientAndFirstDigit[i]) * indexToCalculateSecondDigit[i])
        }
        const secondDigit = Math.trunc(totalSecond / 2) % 10
        return companyAndClientAndFirstDigit.concat(secondDigit.toString())
    }

    render() {
        debugger;
        let barCode
        let apiKey
        if(this.props.location=="history"){
            barCode = this.buildBarCode(this.props.currentUser)
            apiKey = "04a36ec1-f7b5-43de-ad1c-897de6bf46aa"
        }
        //const referenciaPago = this.props.currentUser.SALDO_TOTAL
	    //console.debug(referenciaPago)
        return (
            <Container>
                <Row className={"block"}>
                    <Col sm="6">
                        <Button color="primary" onClick={this.changeLocation}>{this.props.text}</Button>
                    </Col>
                    <Col sm="3">
                        <form action="https://pagosenlinea.pagofacil.com.ar/api/pagosimplificado" method="POST"
                              target="_blank">
                            <input type="hidden" id="api_key" name="api_key"
                                   value={apiKey}/>
                            <input type="hidden" id="codigo_barra" name="codigo_barra" value={barCode}/>
                            <button type="submit" variant="outlined" className="btn btn-default"><img
                                src="./logoPagoFacil.png" height="25px" width="25px"/>Pago Fácil
                            </button>
                        </form>
                    </Col>

                    <Col sm="3" className={"text-right"}>
                        <FaPrint className={"mr-1"}/> <a href="#" className={"print-text"}
                                                         onClick={this.print}>Imprimir</a>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ChangeLocation;
