import ApiClient from './apiClient';

class TableUtils {
    settings() {
        return {
            previousText: "Anterior",
            nextText: 'Siguiente',
            loadingText: 'Cargando...',
            noDataText: 'No se encontraron resultados',
            pageText: 'Página',
            ofText: 'de',
            rowsText: 'registros',
            pageJumpText: 'ir a la página',
            rowsSelectorText: 'registros por página'
        }
    }
    toCurrency(number) {
        return new Intl.NumberFormat('es-AR', { 
            style: 'currency', 
            currency: 'ARS',
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
          }).format(number);
    }
    toPercent(number) {
        return new Intl.NumberFormat('es-AR', { 
            style: 'decimal', 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
          }).format(number) + ' %';
    }
    sortAsc(list, field) {
        list.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
        return list;
    }
    sortDesc(list, field) {
        list.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0));
        return list;
    }
    download(token, action) {
        let apiClient = new ApiClient(token);
        apiClient.download(action).then(res => {
            const file = new Blob([res], {
                type: "application/pdf"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(err => {
            console.error(err);
        });
    }
}

export default TableUtils;