import React, { Component, Fragment } from 'react';
import OrderList from '../Order/List';
import UserInfo from '../User/Info';
import ChangeLocation from '../ChangeLocation';

class Order extends Component {  
    
    render() {
        
        return (
            <Fragment>
                <UserInfo location={"order"} userSelected={this.props.userSelected} />
                <ChangeLocation location={this.props.orderBackTo} text={"Volver"} locationChangedHandler={this.props.locationChangedHandler}></ChangeLocation>
                <OrderList token={this.props.token} orderSelected={this.props.orderSelected} />
            </Fragment>
        )
    }
}

export default Order;