import Config from './config';

class ApiClient {
    constructor(token) {
        this.config = new Config();
        this.token = token;
    }

    async request(method, action, body, contentType = 'application/json') {
        let headers = {
            "Content-Type": contentType,
            "AccessKey": this.token.accessKey,
            "Login": this.token.login
        };
        return fetch(this.config.API_URL + action, {
                method: method,
                headers: headers,
                body: body == null ? null : JSON.stringify(body)
        });
    }

    async get(action) {
        return this.request('GET', action, null).then(response => {
            return response.json();
        });
    }

    async post(action, body) {
        return this.request('POST', action, body).then(response => {
            return response.json();
        });
    }

    async put(action, body) {
        return this.request('PUT', action, body).then(response => {
            return response.json();
        });
    }

    async delete(action, body) {
        return this.request('DELETE', action, body).then(response => {
            return response.json();
        });
    }

    async download(action) {
        return this.request('GET', action, null, 'application/pdf').then(res => res.blob());
    }
}

export default ApiClient;