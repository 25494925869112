import React, { Component, Fragment } from 'react';
import ReactTable from "react-table-v6";  
import "react-table-v6/react-table.css";
import ApiClient from '../../apiClient';
import TableUtils from '../../tableUtils';
import { Container } from 'reactstrap';
import OrderHeader from '../Order/Header';

class OrderList extends Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: []
        };
    }

    componentDidMount() {
        let apiClient = new ApiClient(this.props.token);
        apiClient.get('order/' + this.props.orderSelected.DIVISION_PEDIDO + '/' + this.props.orderSelected.TIPO_PEDIDO + '/' + this.props.orderSelected.NUMERO_PEDIDO).then(res => {
            this.setState({ list: res.data ? res.data : [], loading: false });
        })
        .catch(err => {
            console.error(err);
        });
    }

    render() {
        
        if (this.state.loading) {
            return (
                <p className={"text-center"}>Cargando...</p>
            );
        }

        let tableUtils = new TableUtils();

        const columns = [
            { Header: 'Kit', accessor: 'KIT', width: 60 },
            { Header: 'Descripción', accessor: 'DESCRIPCION' },
            { Header: 'Q Ped', className: "text-right", accessor: 'CANTIDAD_PEDIDA', width: 60 },
            { Header: 'Q Fact', className: "text-right", accessor: 'CANTIDAD_FACTURADA', width: 60 },
            { Header: '$ Cat', className: "text-right", id: 'PRECIO_CATALOGO', accessor: d => { return tableUtils.toCurrency(d.PRECIO_CATALOGO) }, width: 90 },
            { Header: '% Des Of', className: "text-right", id: 'DESCUENTO_POR_OFERTAS', accessor: d => { return tableUtils.toPercent(d.DESCUENTO_POR_OFERTAS) }, width: 90 },
            { Header: '$ Unit', className: "text-right", id: 'PRECIO_UNITARIO', accessor: d => { return tableUtils.toCurrency(d.PRECIO_UNITARIO) }, width: 90 },
            { Header: 'Total', className: "text-right", id: 'IMPORTE', accessor: d => { return tableUtils.toCurrency(d.IMPORTE) }, width: 100 },
        ];

        return (
            <Fragment>
                <OrderHeader orderSelected={this.props.orderSelected} list={this.state.list} />
                <Container>
                    <ReactTable
                    {...tableUtils.settings()}
                    data={this.state.list}  
                    columns={columns}  
                    defaultPageSize = {10}
                    sortable={false}
                    pageSizeOptions = {[10,20,50,100]}
                />
                </Container>
            </Fragment>
        )
    }
}

export default OrderList;