import React, { Component, Fragment } from 'react';
import TableUtils from '../../tableUtils';
import logo from '../../logo.png';
import { FaUser, FaCreditCard, FaCalendar, FaShoppingCart, FaClock, FaMoneyBill } from 'react-icons/fa';
import { Container, Card, Row, Col } from 'reactstrap';
const moment = require('moment');

class UserInfo extends Component {  
    
    render() {
        
        let tableUtils = new TableUtils();

        return (
            <Fragment>
                <div className={"header"}>
                    <img className={"img-logo"} src={logo} alt="Logo" />
                    <div className={"user-name"}><FaUser className={"icon mr-2"} /> {this.props.userSelected.NOMBRE_CLIENTE}</div>
                </div>
                <div className={"header-title"}><img src={require('../../' + this.props.location + '.svg')} alt="Bagues" /></div>
                <Container>
                    <Row className={"block"}>
                        <Col sm="6">
                            <Card body className={"h-100"}>
                                <div className={"block-inner font-weight-bold"}><FaCreditCard className={"icon mr-2"} /> Límite de Crédito: {tableUtils.toCurrency(this.props.userSelected.LIMITE_CREDITO)}</div>
                                <div className={"block-inner block-inner-without-icon"}>Crédito utlizado: {tableUtils.toCurrency(this.props.userSelected.LIMITE_CREDITO_UTILIZADO)}</div>
                                <div className={"block-inner-without-icon"}>Crédito disponible: {tableUtils.toCurrency(this.props.userSelected.LIMITE_CREDITO_DISPONIBLE)}</div>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card body className={"h-100"}>
                                <div><FaCalendar className={"icon mr-2"} /> Fecha de consulta: {moment.utc(this.props.userSelected.FECHA_CONSULTA).format('DD/MM/YYYY')}</div>
                                <hr />
                                <div><FaShoppingCart className={"icon mr-2"} /> Pedido máximo permitido: {tableUtils.toCurrency(this.props.userSelected.MAXIMO_IMPORTE_PEDIDO)}</div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"block"}>
                        <Col sm="6">
                            <Card body className={"block-pink"}>
                                <div><FaClock className={"mr-2"} /> A vencer próximo día D: {tableUtils.toCurrency(this.props.userSelected.SALDO_DIA_D)}</div>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card body className={"block-gray"}>
                                <div><FaMoneyBill className={"mr-2"} />Saldo total: {tableUtils.toCurrency(this.props.userSelected.SALDO_TOTAL)}</div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default UserInfo;