import React, { Component, Fragment } from 'react';
import logo from '../logo.png';
import { Row, Col, Container, Card, CardText } from 'reactstrap';

class Unauthorized extends Component {  
    render() {
        return (
            <Fragment>
            <div className={"header-unauthorized"}>
                <img className={"img-logo"} src={logo} alt="Logo" />
            </div>
            <Container>
                <Row>
                    <Col>
                        <Card body>
                            <CardText>
                                No se encuentra autorizado para acceder a este sitio.
                            </CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </Fragment>
        )
    }
}

export default Unauthorized;